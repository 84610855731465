<template>
  <div>
    <div class="head-bg">
      <div class="ruls" @click="showrule = true">规则</div>
      <van-image width="100vw" :src="detail.merge_image_bg" />
    </div>
    <div class="meng"></div>
    <div class="content">
      <div class="content-item">
        <div class="title">
          <div class="name">
            诛天至尊
            <img :src="levelsChange(4)" alt="" />
          </div>
          <div class="num">限量合成{{ detail.number }}份</div>
        </div>
        <div class="cl-box">
          <div class="title"><img src="./img/cailiao1.png" alt="" /></div>
          <div class="hero" v-if="detail.items && detail.items.length">
            <div class="hero-item" v-for="(item, index) in detail.items" :key="index">
              <div class="box1">
                <img :src="item.image_icon" alt="" />
                <div class="num">{{ item.user_num }}</div>
                <div class="item-meng" @click="heroFn" v-if="item.user_num < item.number"></div>
                <div class="neednum">
                  需<span>{{ item.number }}</span
                  >个
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper">
          <cpdBtn
            :time="detail.start_time"
            :end_time="detail.end_time"
            :hasAll="detail.hasAll"
            @finistTime="finistTime"
            :is_merge="detail.is_merge"
            @compoundHreo="compoundHreo"
            :server_time="server_time"
          />
        </div>

        <!-- <div class="bt-words" v-if="diffTime(detail.start_time) > 0">
          拥有“<span>传说级藏品</span> ”，可在{{
            timeChange(detail.priority_start_time, 'MM月DD日HH:mm')
          }}-{{ timeChange(detail.start_time, 'HH:mm') }}优先合成
        </div> -->
      </div>
      <div class="content-item">
        <div class="sub-title">
          <img src="./img/ztTitle1.png" alt="" />
        </div>
        <div class="sub-content">
          <div>1.持有「诛天至尊」特定藏品编号的8位用户，各获得300元现金红包。</div>
          <div>2.持有「诛天至尊」的用户，将获得后续指定藏品空投权益1次。</div>
        </div>
      </div>
      <div class="content-item">
        <div class="sub-title">
          <img src="./img/ztTitle2.png" alt="" />
        </div>
        <div class="sub-content ztSubContent">
          <div>此境为“星河”到“宇宙”的衍化。</div>
          <div>
            以本源小世界为核，把其余小世界汇聚一处，形成此间天地间唯一“原初星核”后，再以修行者意志为导火线，来彻底将“核”点燃，此后便会立马迎来“太初时刻”，若成功度过则宇宙生；失败，则连同修行者本身也会跟着湮灭于天地大道之间。若能成功踏足此境，则体内的宇宙会与真正大宇宙产生呼应，并水乳交融。
          </div>
          <div>届时，修行者便可成为真正意义的主宰，达到真正意义上的永生！</div>
        </div>
      </div>
      <div class="brigt">
        <div class="">
          <div class="t-c">
            <img class="wid237" :src="require('@/imgs/comm/Slice15hc.png')" alt="" />
          </div>
          <div class="t-c f-10 brigt-content c-07">诛天至尊限量合成{{ detail.number }}份</div>

          <div class="">
            <div class="flex-bt marg36">
              <img class="wid90" :src="require('@/imgs/comm/Slice20.png')" alt="" />
              <img class="wid90" :src="require('@/imgs/comm/Slice21.png')" alt="" />
            </div>
            <div class="flex-bt">
              <img class="wid90" :src="require('@/imgs/comm/Slice22.png')" alt="" />
              <img class="wid90" :src="require('@/imgs/comm/Slice23.png')" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="parameter f-12">
        <div class="">
          <div class="title t-c">
            <img class="wid237" :src="require('@/imgs/comm/Slice16.png')" alt="" />
          </div>
          <div class="bright-item">
            <span class="c-07">商品名称</span>
            <span> {{ productDetail.items_name }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">商品类型</span>
            <span> {{ productDetail.type_name }}</span>
          </div>

          <div class="bright-item">
            <span class="c-07">品牌方</span>
            <span> {{ productDetail.producter }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">发行方</span>
            <span> {{ productDetail.publisher }}</span>
          </div>
          <div class="bright-item" v-if="productDetail.publish_date">
            <span class="c-07">发行时间</span>
            <span> {{ timeChange(productDetail.publish_date, 'YYYY年MM月DD日') }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">商品编号</span>
            <span> {{ productDetail.series_num }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 规则说明 -->
    <van-action-sheet v-model:show="showrule" :round="false" style="background-color: #1c172a">
      <div class="header">
        <img src="@/imgs/comm/pic_tc_zs.png" class="img-zs" />
        <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="showrule = false" />
        <img src="@/imgs/comm/pic_tc_title.png" class="img-title" />
        <span>合成须知</span>
      </div>
      <div class="block"></div>
      <div class="content-van">
        <div class="nei">
          <div class="nei-title">合成时间：</div>
          2022年8月25日16:00至2022年8月26日15:59
          <br /><br />
          <div class="nei-title">合成材料：</div>
          「天尊」*2+「斗帝」*5+「武圣」*5+「尊者」*5+「真武」*5+「邪皇」*5可合成传说级「诛天至尊」*1份
          <br /><br />
          <div class="nei-title">合成规则：</div>

          1、合成限量200份，先到先得。<br /><br />
          2、正式开启合成后，每人合成次数不限制。<br /><br />
          3、藏品编号在合成的时候随机生成。特定藏品编号根据26号下午15:00上证指数的个位数X做尾数，第x、1x、3x、5x、7x、11x、13x、15x的8位用户获得奖励。
          如：上证指数个位数为6，则取6、16、36、56、76、116、136、156藏品编号（若该编号没有，则失效)。<br /><br />
          4、现金红包合成结束后24小时到账，可在“我的红包”里面查看并领取，若未满足要求，剩余的现金红包奖励将自动失效。
          <br /><br />
          5、合成成功后，使用的材料将被销毁。
          <br /><br />

          <p class="t-c">最终解释权归看漫所有</p>
        </div>
      </div>
    </van-action-sheet>
    <PageLoading :show="loading" />
    <compoundPop
      :compoundPopShow="compoundPopShow"
      :effect_img="effect_img"
      :addnum="addnum"
      @close="(compoundPopShow = false), finistTime()"
    />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { getmergeitems } from '@/service/home';
import { zhutianwanyustuff } from '@/service/compound';
import cpdBtn from '@/components/CpdBtn';
import compoundPop from './components/ztCompoundPop.vue';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
import { Toast } from 'vant';
import { shareHelper } from '@/helpers';
import { domUtil } from '@/utils';
import { produceDetail } from '@/service/home';
export default {
  components: {
    cpdBtn,
    compoundPop,
    PageLoading
  },
  setup() {
    const state = reactive({
      showrule: false,
      detail: {},
      compoundPopShow: false,
      token_id: 0, // 选择的材料中的id
      effect_img: '',
      loading: true,
      server_time: 0,
      addnum: 10, //材料2 选择数量
      max: 0, //材料2最大数量
      shinum: 0,
      nvnum: 0,
      pointnum: 0,
      productDetail: {}
    });

    onMounted(async () => {
      finistTime();
      const shareConf = {
        title: '看漫数藏',
        content: '由看漫APP倾力打造，藏品有限，价值无限',
        image: '',
        link: window.location.origin + '/home', // app分享链接
        url: window.location.origin + '/home' // app分享链接
      };
      // 设置微信分享和app内部分享(shareInfo：为动态获取数据。如果是静态的分享，则直接在data初始化中设置)
      shareHelper.init(shareConf);
      // 设置index.html中的头部分享信息
      domUtil.setDomShareMeta(shareConf);

      const { data } = await produceDetail({ collections_id: 22 });
      data.length && (state.productDetail = data[0]);
      // data.map(item => {
      //   if (item.id == 58) {
      //     state.productDetail = item;
      //   }
      // });
    });
    const heroFn = async () => {
      Toast('该材料不足，快去购买材料吧～');
    };
    const finistTime = async () => {
      const { data, server_time } = await zhutianwanyustuff();
      state.detail = data;
      state.server_time = server_time;
      state.effect_img = data.effect_img;

      let merge_config_items = data.items,
        user_merge_config_items = data.user_stuff_list,
        arr = [],
        subitem,
        hasAll = true; // 材料是否充足;
      // 循环得出用户拥有数

      merge_config_items.map((item, index) => {
        if (user_merge_config_items.length == 0) {
          hasAll = false;
          console.log(11111);
          arr.push({ ...item, user_num: 0 });
        } else {
          for (let i = 0; i < user_merge_config_items.length; i++) {
            subitem = user_merge_config_items[i];

            if (item.token_id == subitem.token_id) {
              arr.push({ ...item, user_num: subitem.number });
              // 判断材料2 选择第一个
              // 材料1不足
              if (subitem.number < item.number) {
                hasAll = false;
              }

              break;
            }
            if (user_merge_config_items.length - 1 == i && item.token_id != subitem.token_id) {
              arr.push({ ...item, user_num: 0 });
              // 材料不足
              hasAll = false;

              break;
            }
          }
        }
      });

      state.detail.items = arr;
      // state.max =
      //   state.detail.items[4].user_num < 110
      //     ? Math.floor(state.detail.items[4].user_num / 10) * 10
      //     : 110;
      console.log(hasAll, arr, '....');
      // state.addnum = state.max;
      state.detail = { ...state.detail, hasAll };
      state.loading = false;
    };
    // 合成按钮
    const compoundHreo = () => {
      state.compoundPopShow = true;
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const userNum = id => {
      return 1;
    };
    const timeChange = (time, formtype) => {
      return moment(time).format(formtype);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      levelsChange,
      userNum,
      finistTime,
      compoundHreo,
      timeChange,
      heroFn,
      diffTime,
      icon_tc_gb: require('@/imgs/icon/icon_tc_gb.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.stepper {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  margin-top: 10px;
  align-items: center;
  .gre {
    color: #453d5b;
  }
  .num-box {
    width: 50px;
    height: 26px;
    background: #453d5b;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #a297bf;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0 7px;
  }
}
.add-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-hero {
  margin-top: 26px;
  display: flex;
  align-items: center;

  .add-left {
    width: 50%;
    padding-left: 40px;
    box-sizing: border-box;
  }
  .add-right {
    display: flex;
    align-items: center;
    .line {
      margin-right: 20px;
      width: 0px;
      height: 77px;

      border: 1px solid #453d5b;
    }
    .add-r-item {
      margin-bottom: 10px;
      .title {
        font-size: 12px;
        font-weight: 400;
      }
      .num {
        font-size: 16px;
        font-weight: 500;
        span {
          color: #3cdbe8;
        }
        .red {
          color: #e83c3c;
        }
      }
    }
  }
}
.van-action-sheet {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    padding-top: 3px;

    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .img-title {
      height: 16px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }
  .block {
    height: 80px;
    background-color: $bc;
  }
  .content-van {
    background-color: $bc;
    color: $fc;
    font-size: 14px;

    .nei {
      margin-left: 16px;
      margin-right: 16px;

      color: rgba(255, 255, 255, 0.7);
      .nei-title {
        color: rgba(255, 255, 255, 1);
      }
      span {
        color: #3cdbe8;
      }
      p {
        margin: 0px;
        margin-bottom: 40px;
      }
    }
  }
}
.brigt-content {
  margin-bottom: 24px;
}
.head-bg {
  position: relative;
  .ruls {
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: 9;
    width: 64px;
    height: 24px;
    background: #58dedd;
    font-size: 12px;
    color: #000000;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    padding-left: 20px;
    box-sizing: border-box;
    font-weight: 500;
    // justify-content: center;
    align-items: center;
  }
}
.f-12 {
  font-size: 12px;
}
.parameter {
  @include bis('../../imgs/comm/Slice9.png');
  padding: 10px 21px 27px;
  .title {
    margin-bottom: 19px;
    font-size: 0px;
  }
  .bright-item {
    @include fj();
    padding-bottom: 6px;
    margin-top: 10px;
    border-bottom: 1px solid #494359;
  }
}
.c-07 {
  color: rgba(255, 255, 255, 0.7);
}
.brigt {
  padding: 24px 54px 38px;
  @include bis('../../imgs/comm/Slice9.png');
  margin-bottom: 24px;
}
.t-c {
  text-align: center;
}
.f-10 {
  font-size: 10px;
}
.f-0 {
  font-size: 0px;
}
.flex-bt {
  @include fj();
}
.wid237 {
  width: 237px;
}
.wid90 {
  width: 90px;
  height: 100px;
}
.marg36 {
  margin-bottom: 26px;
}
.bt-words {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  span {
    font-weight: 600;
  }
}
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 11px 0;
}
.hc-btn {
  background: url('../../imgs/comm/hcbtn.png') no-repeat;
  background-size: 100% 100%;
  width: 164px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #20322c;
}
.meng {
  width: 100vw;
  height: 160px;
  position: relative;
  top: -160px;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 75%);
}
.content {
  padding: 0 16px 10px;
  position: relative;
  top: -247px;
}
.add {
  font-size: 24px;
  text-align: center;
  // margin: 4px 0;
}
.content-item2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    width: 100%;
    margin-top: 6px;
    img {
      width: 237px;
      margin: 0 auto;
    }
  }
}
.content-item {
  background: #2f293f;
  padding: 34px 11px 10px;
  margin-bottom: 14px;
  margin-top: 36px;
  position: relative;
  .sub-title {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    font-size: 0px;
    // .title-img {
    //   width: 100%;
    //   height: 50px;
    // }
    img {
      width: 192.5px;
      height: 36px;
    }
  }
  .sub-content {
    // margin-top: 34px;
    font-size: 12px;

    color: rgba(255, 255, 255, 0.7);
    span {
      color: rgba(255, 255, 255, 1);
      font-weight: bold;
    }
    p {
      margin: 2px 0;
    }
    div {
      margin-bottom: 10px;
      &:nth-child(1) {
        margin-bottom: 15px;
      }
    }
  }
  .ztSubContent {
    div {
      margin-bottom: 0;
      &:nth-child(1) {
        margin-bottom: 0;
      }
    }
    &:last-child {
      margin-bottom: 10px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    .name {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
    img {
      width: 50px;
      margin-left: 5px;
    }
    .num {
      background: #3cdbe8;
      color: #2f293f;
      width: 85px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      span {
        font-weight: 500;
      }
    }
  }
  .cl-box {
    background: #1c172a;
    padding: 0 5px 10px 5px;
    margin-top: 18px;
    .title {
      text-align: center;
      font-size: 0px;
      padding: 5px 0;
      img {
        width: 237px;

        margin: 0 auto;
      }
    }
    .hero-wrapper {
      display: flex;
      justify-content: center;
    }
    .hero1 {
      padding: 0 27px;
    }
    .hero {
      display: flex;
      flex-wrap: wrap;
      font-size: 0px;
      align-items: center;
      justify-content: space-around;
      //justify-content: space-between;
      img {
        width: 70px;
      }
      .mr10 {
        margin-right: 10px;
      }
      .choose {
        border: 1px solid #5fdfd3;
        border-radius: 4px;
        background-color: #2f293f;
      }
      .nochoose {
        border: 1px solid #1c172a;
        border-radius: 4px;
      }
      // .w33 {
      //   width: 33%;
      // }
      .hero-item {
        width: 33%;
        // position: relative;

        .box1 {
          // margin-right: 10px;
          // &:nth-child(3n + 4) {
          //   margin-right: 0px;
          // }
          position: relative;
          margin: 0 auto;
          text-align: center;
        }
        .martop14 {
          margin-top: 14px;
        }
        .num {
          position: absolute;
          right: 9px;
          top: 9px;
          background: #2f293f;
          font-size: 12px;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          z-index: 2;
        }
        .neednum {
          font-size: 12px;
          text-align: center;
          span {
            color: $primary;
          }
        }
        .item-meng {
          width: 69px;
          height: 73px;
          background: #2f293f;
          opacity: 0.5;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
